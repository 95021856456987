<template>
  <div class="project_detail">
    <!-- <headers></headers> -->
    <div class="detail_wrapper manage-wrapper">
      <!-- <el-breadcrumb separator="/" class="bread-crumb">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/home/manage' }">项目课管理</el-breadcrumb-item>
        <el-breadcrumb-item>课程详情</el-breadcrumb-item>
      </el-breadcrumb> -->
      <div class="project-detail-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="课程详情" name="first">
            <el-form ref="form" :model="form" label-width="180px">

              <el-form-item label="课程名称：" :required="true">
                {{form.name||''}}
              </el-form-item>

              <el-form-item label="课程简介：">
                <p class="introduce" v-html="form.courseExplain||'无'">{{form.courseExplain||'无'}}</p>
              </el-form-item>

              <el-form-item label="是否为精品课：" :required="true">
                {{form.boutique ? '是':'否'}}
              </el-form-item>

              <el-form-item label="所属项目：" :required="true">
                {{form.projectName}}
              </el-form-item>

              <el-form-item label="授课模式：" :required="true">
                <p>{{form.lessonsPattern == 0 ?'同步课':'异步课'}}</p>
              </el-form-item>

              <el-form-item label="班型：" :required="true" v-if="form.lessonsPattern == 0">
                {{courseTypeMap[form.classType]}}
              </el-form-item>

              <el-form-item label="课程有效期：" v-if="form.lessonsPattern == 1">
                {{courseInData[form.lessonsInDate]}}
              </el-form-item>

              <el-form-item label="课程类型：">
                {{form.courseTypeName}}
              </el-form-item>

              <el-form-item label="课程学科：">
                {{form.subjectName}}
              </el-form-item>

              <el-form-item label="主题封面：">
                <p v-if="form.cover === ''">无</p>
                <img :src="downloadURL + form.cover" alt="" class="cover" v-else />
              </el-form-item>

              <el-form-item label="用户组：" :required="true">
                <el-cascader style="width:100%" :options="groupInfo" v-model="form.userGroupId"
                  :props="{ multiple: true , value: 'id',emitPath:false}" disabled></el-cascader>
              </el-form-item>

              <div class="teahcers flex-align" v-if="form.lessonsPattern == 0">
                <el-form-item label="授课老师：" :required="true">
                  {{form.teacherName}}
                </el-form-item>
                <el-form-item label="助教老师：">
                  {{form.assistantName}}
                </el-form-item>
              </div>

              <el-form-item label="主讲教师：" class="teahcers" v-if="form.lessonsPattern == 1">
                {{form.teachName||'未知'}}
              </el-form-item>

              <el-form-item label="课程标签：">
                {{form.courseLabel ||'无'}}
              </el-form-item>

              <el-form-item class="el-form-item-btns" label-width="0">
                <el-button type="primary" @click="goEdit">编辑</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeName: "first",
        form: {}, //表单信息
        courseTypeMap: {
          1: '百家云大班普通课',
          2: '百家云大班普通课',
          3: '百家云大班伪直播',
          4: '百家云小班课',
          5: 'classin标准课',
          6: '腾讯云课堂',
        },
        courseInData: {
          1: '永久有效',
          2: '一年',
        },
        groupInfo: [], //用户组
      };
    },
    methods: {
      // 跳转编辑页
      goEdit() {
        this.$router.push({
          path: `/course/edit/${this.$route.params.id}`,
          query: {
            proId: this.$route.query.proId,
            breadNumber: 4,
          }
        });
      },
      // 通过id查询课程信息
      async getfindCourse() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.findCourse(id);
        this.form = resData.data;
        // console.log(this.form)
        Vue.set(this.form, 'projectName', resData.data.project.name);
        Vue.set(this.form, 'teacherName', resData.data.teacher.name);
        Vue.set(this.form, 'assistantName', resData.data.assistant ? resData.data.assistant.name : '无');
        Vue.set(this.form, 'courseTypeName', resData.data.courseTypeEntity ? resData.data.courseTypeEntity.typeName :
          '无');
        Vue.set(this.form, 'subjectName', resData.data.subject ? resData.data.subject.name : '无');
        let arr = [];
        (this.form.userGroupIds.split(',').map(item => {
          arr.push(Number(item));
        }))
        Vue.set(this.form, 'userGroupId', arr)
        this.getGroupInfo()
      },
      // 获取用户组选项
      async getGroupInfo() {
        let id = this.$route.query.proId;
        let resData = await this.$Api.Course.GroupInfo(id);
        this.groupInfo = resData.data;
      }
    },
    mounted() {
      window.scroll(0, 0)
      this.getfindCourse();
    },
  };
</script>

<style lang="less" scoped>
  @import "./less/manage-project.less";

  .project-detail-container {
    .introduce {
      padding-right: 40px;
      word-wrap: break-word;
      word-break: break-all;

    }

  }
</style>